//Dropdown SCSS
.dropdown-menu.show {
  display: block;
}
.dropdown-menu-right {
  right: 0;
  left: auto;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 250px;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: #3e5569;
  text-align: left;
  background-color: #fff;
  border: 1px solid #e9ecef;
  border-radius: 2px;
}
.dropdown-menu,
.modal-content,
.popover,
.toast,
.toast-header {
  background-clip: padding-box;
}
.dropdown-menu,
.nav,
.navbar-nav {
  list-style: none;
}

.dropdown-item {
  display: block;
  width: 100%;
  clear: both;
  color: #212529;
  text-align: inherit;
  background-color: transparent;
  border: 0;
}

.dropdown-selector-item {
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;

  img {
    margin-right: 5px;
    width: 30px;
  }

  &:active {
    background-color: white;
  }
}

.dropdown-selector {
  position: absolute;
  top: 40%;
  z-index: 1000;
  display: none;
  min-width: 250px;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: #3e5569;
  text-align: left;
  background-color: #fff;
  border: 1px solid #e9ecef;
  border-radius: 10px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
  right: 70px;
  font-family: Roboto Light;
  font-weight: 300;
  font-size: 16px;
  line-height: normal;
  letter-spacing: normal;
  color: black !important;
  text-transform: capitalize;

  &.show {
    display: block;
  }

  &.menu-vert {
    top: 3%;
    font-family: 'Roboto';
    font-size: 12px;
    letter-spacing: 1.71px;
    font-weight: normal;
  }

  a:hover {
    background-color: lightgray;
  }
}
