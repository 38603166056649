.button-rounded {
  border-radius: 15px;
  border: solid 2px #e1e1e1;
  background-color: transparent;
  padding-left: 15px;
  padding-right: 15px;
  text-transform: capitalize;

  img {
    padding-left: 5px;
  }
}

.view-link {
  text-decoration: underline;
  font-weight: 300;
  font-size: 20px;
  padding-top: 5px;
  a {
    color: #171c8f !important;
  }
}