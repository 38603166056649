.loading-container {
  display: flex;
  justify-content: center;

  .loading-image {
    width: 80px;

    animation: loading 3s linear infinite;

    @keyframes loading {
      0% {
        transform: rotate(0);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
