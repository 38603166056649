.input-form {
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  text-align: left;

  .title {
    font-weight: normal;
    color: #000000;
    margin-bottom: 10px;
  }

  .description {
    margin-top: 8px;
  }
}

.input-textarea {
  width: 100%;
  margin-top: 5px;
  border-radius: 5px;
  border: 1px solid #e1e1e1;
  resize: none;

  &.padding {
    padding: 10px;
  }

  &.long {
    height: 100px;
  }

  &.arabic {
    direction: rtl;
  }
}
