// Chartist library modifications
.ct-point {
  stroke-width: 5px !important;
  stroke-linecap: butt !important;
}

#line-area {
  width: 100%;
  height: 100%;
}

.ct-line {
  fill: none;
  stroke-width: 2px !important;
}

.ct-series-b .ct-point,
.ct-series-b .ct-line,
.ct-series-b .ct-bar,
.ct-series-b .ct-slice-donut {
  stroke: #ff8d65 !important;
}

.ct-series-c .ct-point,
.ct-series-c .ct-line,
.ct-series-c .ct-bar,
.ct-series-c .ct-slice-donut {
  stroke: #e35d79 !important;
}

.ct-series-a .ct-point,
.ct-series-a .ct-line,
.ct-series-a .ct-bar,
.ct-series-a .ct-slice-donut {
  stroke: #171c8f !important;
}

.ct-grid {
  stroke: rgba(0, 0, 0, 0.2);
  stroke-width: 0.3px !important;
  stroke-dasharray: 0px !important;
}

.ct-series-c .ct-slice-pie,
.ct-series-c .ct-slice-donut-solid,
.ct-series-c .ct-area {
  fill: #ff8d65 !important;
  fill-opacity: 0 !important;
}

.ct-series-b .ct-slice-pie,
.ct-series-b .ct-slice-donut-solid,
.ct-series-b .ct-area {
  fill: #ff8d65 !important;
  fill-opacity: 0.2 !important;
}

.ct-series-a .ct-slice-pie,
.ct-series-a .ct-slice-donut-solid,
.ct-series-a .ct-area {
  fill: #ff8d65 !important;
  fill-opacity: 0 !important;
}

.ct-label {
  fill: rgba(0, 0, 0, 0.4) !important;
  color: rgba(0, 0, 0, 0.4) !important;
  font-size: 0.75rem !important;
  line-height: 1 !important;
}

.ct-label.ct-vertical.ct-start {
  font-family: 'Roboto Light' !important;
  font-weight: 500 !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: 1.5 !important;
  letter-spacing: normal !important;
  text-align: right !important;
  color: #000000 !important;
}
