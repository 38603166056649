mat-grid-list mat-grid-tile.clsCardApp {
  border-radius: 10px;
  border: 1px solid #e1e1e1;
  padding-top: 116px !important;
  min-width: 213px;
}

mat-grid-list mat-grid-tile.clsCardApp3 {
  padding-top: 112px !important;
  min-width: 213px;
}

mat-grid-list mat-grid-tile.clsCardApp.clsPassed {
  border: 1px solid #48c784;
}

mat-grid-list mat-grid-tile.clsCardApp.clsFailed {
  border: 1px solid #a50034;
}

mat-grid-list mat-grid-tile.clsCardApp.selected {
  border: 1px solid #171c8f;
}

mat-grid-list mat-grid-tile.clsCardApp .clsDeviceName {
  font-family: 'Roboto Bold';
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: black;
  margin-top: 18px;
  padding-left: 0px;
  padding-right: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
mat-grid-list mat-grid-tile.clsCardApp .clsDeviceTestStatus {
  margin-top: 5px;
  font-family: 'Roboto Light';
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  padding-left: 0px;
  padding-right: 16px;
}
mat-grid-list mat-grid-tile.clsCardApp .deviceSelected {
  margin-top: 26px;
}
mat-grid-list mat-grid-tile.clsCardApp .clsDeviceTestStatus.passed {
  font-family: 'Roboto Bold';
  letter-spacing: 2px;
  color: #48c784;
}
mat-grid-list mat-grid-tile.clsCardApp .clsDeviceTestStatus.failed {
  font-family: 'Roboto Bold';
  letter-spacing: 2px;
  color: #a50034;
}
mat-grid-list mat-grid-tile.clsCardApp .clsDeviceOsVersion {
  font-family: 'Roboto Light';
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: black;
  margin-top: 26px;
  padding-left: 0px;
  padding-right: 16px;
}

mat-grid-list mat-grid-tile.clsCardApp .clsDeviceAssets {
  margin-top: 26px;
  padding-right: 16px;
  text-align: right;
}

mat-grid-list mat-grid-tile.clsCardApp .clsDeviceAssets .clsReport {
  margin-top: -10px;
  margin-right: 10px;
}

mat-grid-list mat-grid-tile.clsCardApp .clsDeviceAssets .clsSelect {
  margin-top: -4px;
}

mat-grid-list mat-grid-tile.clsCardApp.selected {
  border: 1px solid #171c8f;
}

.clsMarginTop18px {
  margin-top: 18px;
}

mat-grid-list mat-grid-tile.clsCardApp .clsDeviceOsVersion img {
  margin-top: -2px;
}
