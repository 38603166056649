.addUserContainerDialog {
    
    background-color: white;
    
    .mat-dialog-container {
    padding: 50px !important;
    overflow: hidden;
    position: relative
  }
  
  .mat-dialog-title{
    font-family: "Roboto Light";
    font-size: 30px;
    font-weight: 150
  }
}

.assignRolesDialog {
    
  background-color: white;

.mat-dialog-title{
  font-family: "Roboto Light";
  font-size: 30px;
  font-weight: 150
}
}

.deleteUserContainerDialog {

  background-color: white;

  .mat-dialog-container {
    padding: 60px;
  }
}