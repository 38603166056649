.mat-card.clsCard {
  width: 264px;
  border-radius: 10px;
  border: solid 1px #e1e1e1;
  background-color: white;
  padding: 22px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.mat-card.clsCard.main {
  height: 374px;
}

.mat-card.clsCard.configure {
  height: 374px;
}

.mat-card.clsCard.engage {
  height: 374px;
}

@media (min-width: 1170px) {
  .card-body {
    padding: 0;
  }
}

.clsSectionTitleTools {
  font-family: 'Roboto Light';
  font-size: 24px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  margin-top: 50px;

  &.small {
    font-size: 16px;
  }
}
.clsSectionTitle {
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: black;
}
.clsSectionTitleTools.clsMarginMainTitle {
  margin-top: 30px;
}
.clsMatCardGroup {
  margin-top: 20px;
  margin-bottom: 20px;
}
.clsSeparator {
  border: solid 1px #e1e1e1;
  margin-top: 30px;
}
.clsSectionItem {
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: black;
  text-transform: capitalize;
  margin-top: 20px;
}
.mat-card.clsCard .clsToolTilte {
  font-family: 'Roboto Bold';
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: black;
  padding-left: 0px;
  padding-right: 0px;
  margin-top: 18px;
  height: 50px;
  place-content: center;
}
.mat-card.clsCard .clsToolInfo {
  font-family: 'Roboto Light';
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: black;
  margin-top: 9px;
  height: 180px;
  overflow: hidden;

  span {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }
}

.mat-card.clsCard.configure .clsToolInfo {
  height: 180px;
}
.mat-card.clsCard.engage .clsToolInfo {
  height: 180px;
}

mat-card-content .clsToolBtnMargin {
  margin-top: 20px;
}

mat-card-content .clsToolBtn {
  font-family: 'Roboto Bold';
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.71px;
  color: black;
  cursor: pointer;
  white-space: nowrap;
  text-transform: uppercase;
}

mat-card-content .clsToolBtn img.padlock {
  margin-top: -4px;
}

mat-card-content .clsToolBtn img.link {
  margin-top: -5px;
}
