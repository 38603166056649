//Select
.clsSelect {
  background-color: #ffffff;
  border-radius: 5px;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;

  color: #000000;
  font-family: 'Roboto Light';
  font-weight: 300;
  font-size: 16px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

.ng-select.clsSelect-borderless .ng-select-container {
  border-radius: 0px;
  border: 0;
  border-bottom: 1px solid #ccc;
}

.ng-select .ng-arrow-wrapper {
  display: none;
}
.ng-select .ng-select-container {
  background-image: url('~assets/images/icons/arrow-dropdown.svg');
  background-position: calc(100% - 14px) calc(16px),
    calc(100% - 15px) calc(1em + 3px), 100% 0;
  background-repeat: no-repeat;
  border: 1px solid #e1e1e1;
}
.ng-dropdown-panel.ng-select-bottom {
  margin-top: -0.5px;
}
.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  border-color: #ccc;
  box-shadow: none;
}
.ng-select.ng-select-opened .ng-select-container {
  background-image: url('~assets/images/icons/arrow-up-icon.svg');
  background-position: calc(100% - 14px) calc(16px),
    calc(100% - 15px) calc(1em + 3px), 100% 0;
  background-repeat: no-repeat;
  border-color: #171c8f;
}

.ng-select.clsSelect
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected {
  background-color: #ddd;
}
.ng-select.clsSelect
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-marked {
  background-color: #5897fb;
  color: white;
}

//dots
.clsDotCmb::before {
  content: '';
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 10px;
  margin-left: 6px;
  margin-bottom: -2px;
  display: inline-block;
}

.clsDotCmb.clsDot_dark_mint::before {
  background-color: #48c784;
}

.clsDotCmb.clsDot_cranberry::before {
  background-color: #a50034;
}

.clsDotCmb.clsDot_Loading::before {
  //background-image: url('~assets/images/icons/reload_big_pink.gif');
  background-repeat: no-repeat;
  background-size: 15px 15px;
}

//Badges
.clsBadge {
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  border-radius: 15px;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Roboto';
  text-align: center;

  &_red {
    color: #a50034;
    background-color: rgba(165, 0, 52, 0.2);
  }

  &_green {
    background-color: rgba(72, 199, 132, 0.2);
    color: #48c784;
  }

  &_grey {
    background-color: #e1e1e1;
    color: #000000;
  }

  &_blue {
    background-color: #dfe4ff;
    color: #171c8f;
    font-weight: 300;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

//Badges
.clsBadgeCmn {
  padding: 5px;
  border-radius: 8px;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Roboto';
  height: 24px;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 120px;

  &.findings {
    display: initial;
    padding: 5px 15px;
  }
  &_red {
    background-color: #BB0038;
    color: #FFFFFF;
  }

  &_pink {
    background-color: #FFE8E9;
    color: #CB2A61;
  }

  &_green {
    background-color: #E4FBF4;
    color: #18B289;
  }

  &_grey {
    background-color: #eaeaea;
    color: #959595;
  }

  &_blue {
    background-color: #D9F4FF;
    color: #1FAEFF;
  }

  &_medium {
    background-color: #FFF6D2;
    color: #FD9F16;
  }

  &_red2 {
    color: #a50034;
    background-color: rgba(165, 0, 52, 0.2);
  }
  &_purple{
    color: #AB7CD8;
    background-color: #F1ECFD;
  }
}
