// ---------------------------------------
// ------------ STYLES CARD --------------
// ---------------------------------------

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border: 0 solid transparent;
}

.card {
  margin-bottom: 50px;
}

@media (max-width: 991.98px) {
  .card {
    margin-bottom: 30px;
  }
}
.container,
.container-fluid {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}

@media (max-width: 767px) {
  .card.clsCardContainer {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;
    box-shadow: 0 0 20px 0 rgba(137, 133, 197, 0.2);
    background-color: #ffffff;
  }
}
@media (min-width: 768px) {
  .card.clsCardContainer {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;
    box-shadow: 0 0 20px 0 rgba(137, 133, 197, 0.2);
    background-color: #ffffff;
  }
}

@media (min-width: 1170px) {
  .card.clsCardContainer {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 50px;
    padding-right: 50px;
    border-radius: 10px;
    box-shadow: 0 0 20px 0 rgba(137, 133, 197, 0.2);
    background-color: #ffffff;
    margin-top: 20px;
  }
}

@media (max-width: 991.98px) {
  .card {
    margin-bottom: 30px;
  }
}

.card .clsTopAjust.clsTopAjust {
  margin-bottom: 0px;
}

// ---------------------------------------
// ------------ STYLES TITLES -------------
// ---------------------------------------

.card .clsTopAjust.clsTopAjust {
  margin-bottom: 0px;
}
.card .clsCard_Title {
  font-family: 'Roboto Light';
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #171c8f;
  margin-bottom: 20px;
}
@media (max-width: 991.98px) {
  .card .clsCard_Title {
    font-size: 28px;
    line-height: 1.25;
    margin-bottom: 30px;
  }
}
@media (min-width: 992px) {
  .card .clsCard_Title {
    font-size: 30px;
    line-height: 1.93;
  }
}

.card .clsCard_Subtitle {
  font-family: 'Roboto Light';
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: black;
}

.card .clsCard_Link {
  font-family: 'Roboto';
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 2px;
  font-stretch: normal;
  font-style: normal;
  color: black;
  margin-bottom: 20px;

  img {
    padding-left: 5px;
  }

  a {
    text-decoration: none;

    &:visited {
      color: black;
    }
  }
}

@media (max-width: 991.98px) {
  .card .clsCard_Subtitle {
    font-size: 14px;
    line-height: 0.9;
  }
}
@media (min-width: 992px) {
  .card .clsCard_Subtitle {
    font-size: 16px;
    line-height: 1;
  }
}

// ---------------------------------------
// ------------ STYLES LABEL -------------
// ---------------------------------------
.card .clsCardLabel {
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin-top: 16px;
}

// ---------------------------------------
// ------------ STYLES BUTTONS -------------
// ---------------------------------------
.card .clsCardButton {
  font-family: 'Roboto Bold';
  width: 146px;
  height: 50px;
  border-radius: 25px;
  border: solid 2px #171c8f;
  background-color: transparent;
  padding: 5px 25px 2px 25px;
  font-size: 14px;
  letter-spacing: 2px;
  color: #000000;
  font-weight: normal;
  margin-top: 31px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
}
.card .clsCardButton.clsCardButtonDisabled {
  pointer-events: none !important;
  border-color: #e1e1e1 !important;
  color: #a9a9a9 !important;
  cursor: default;
}

//Style card dialog
.dialogPanelClass .mat-dialog-container {
  display: block;
  padding: 60px;
  box-sizing: border-box;
  overflow: hidden;
  outline: 0;
  width: 100%;
  height: 100%;
  min-height: inherit;
  max-height: inherit;
  background-color: white;
  border-radius: 10px;
}

.cdk-overlay-pane.image-viewer {
  position: relative !important;
}

.image-viewer .mat-dialog-container {
  display: block;
  padding: 0;
  box-sizing: border-box;
  overflow: hidden;
  outline: 0;
  width: 100%;
  height: 100%;
  min-height: inherit;
  max-height: inherit;
  background-color: white;
  border-radius: 10px;

  img {
    width: 100%;
    height: auto;
  }
}
.clsDialogView .mat-dialog-container {
  display: block;
  padding: 40px 0 48px 0;
  box-sizing: border-box;
  overflow: hidden;
  outline: 0;
  width: 100%;
  height: 100%;
  min-height: inherit;
  max-height: inherit;
  background-color: white;
  border-radius: 10px;
}

// CARDS RELOAD WIDGETS
.clsImgCenterVertical_Text {
  top: 47%;
  position: absolute;
  width: auto;
}
.clsTitleCenterVertical {
  font-family: 'Roboto Light';
  font-weight: 300;
  font-size: 30px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  margin-top: 10px;
}
.clsDataCenterVertical {
  font-family: 'Roboto Light';
  font-weight: 300;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  margin-top: 10px;
}
