// Custom Theming for Angular Material
@import '../node_modules/@angular/material/theming';
@import '~@ng-select/ng-select/themes/default.theme.css';

//Import all custom css
@import './theme/all';

@include mat-core();

@font-face {
  font-family: 'Roboto Bold';
  src: url('assets/fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Roboto Medium';
  src: url('assets/fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('assets/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Roboto Light';
  src: url('assets/fonts/Roboto-Light.ttf') format('truetype');
  font-weight: normal;
}

html,
body {
  min-height: 100%;
  margin: 0;
}

body {
  font-family: 'Roboto', 'Helvetica Neue', sans-serif;
}

.mat-select-arrow {
  border: none;
  visibility: hidden;
}

.hide {
  display: none;
}

.align-items-center {
  align-items: center !important;
}

.d-md-flex {
  display: flex !important;
}

dd,
h1,
h2,
h3,
h4,
h5,
h6,
label {
  margin-bottom: 0.5rem;
}

.clsFont16 {
  font-size: 16px;
}

.clsRojo {
  border: 1px solid red;
}

.clsAzul {
  border: 1px solid blue;
}

.clsCursorPointer {
  cursor: pointer;
}

.clsCursorDefault {
  cursor: default !important;
}

button:focus {
  outline: 0px;
}

.clsColorRoyal {
  color: #171c8f;
}

.clsIcon60x60 {
  width: 60px;
  height: 60px;
}
.clsIcon80x80 {
  width: 80px;
  height: 80px;
}

.clsIcon60x60 {
  width: 60px;
  height: 60px;
}
.clsImg-59 {
  width: 59px;
}

.clsRobotoBold {
  font-family: 'Roboto Bold';
}

.clsUnderlineBlue {
  text-decoration: underline;
  text-decoration-skip-ink: none;
  text-decoration-color: #171c8f;
  text-underline-position: under;
}

.clsDisplayNone {
  display: none !important;
}

*::-webkit-scrollbar {
  width: 4px !important;
  height: 4px !important;
}

*::-webkit-scrollbar-track {
  border-radius: 6px !important;
}

*::-webkit-scrollbar-thumb {
  background: #e1e1e1 !important;
  border-radius: 6px !important;
}

*::-webkit-scrollbar-thumb:hover {
  background: #e1e1e1 !important;
}

pre {
  border-radius: 4px !important;
  padding: 20px !important;
  color: black !important;
}
:not(pre) > code[class*='language-'],
pre[class*='language-'] {
  background: #f5f8fb !important;
  color: black !important;
  text-shadow: 0px;
  font-family: 'Roboto Light';
}
blockquote {
  font-size: inherit !important;
}

code[class*='language-'],
pre[class*='language-'] {
  color: black !important;
  text-shadow: none !important;
  font-size: 14px !important;
}

.clsContentCenterVertically {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.clsFindingTargets :not(pre) > code[class*='language-'],
pre[class*='language-'] {
  margin-bottom: 20px !important;
}

blockquote {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 50px;
  padding-left: 15px;
  border-left: 3px solid #ccc;
}

.clsGridTable {
  flex-flow: row wrap;
  box-sizing: border-box;
  display: flex;
  margin: 0px -18px -18px 0px;
}

.clsGridTable .clsCard {
  margin-left: 25px;
}

//Notification Center Modal
.notificationPanelClass .mat-dialog-container {
  width: 700px;
  height: 651px;
  min-height: 0 !important;
  object-fit: contain;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
  background-color: white;
  position: absolute;
  top: 40px;
  left: calc(50% - 350px);
  right: 0;
  z-index: 50;
  padding: 50px;
  border-radius: 10px !important;
  overflow: hidden !important;
}

// Notification Center title Markdown overwrite
.clsNotificationTitle {
  p {
    margin-bottom: 0 !important;
    font-weight: bold;
  }
}

// Notification Modal
.notificationModalPanelClass .mat-dialog-container {
  background-color: white;
  background-image: linear-gradient(132deg, #1a24b8, #171c8f);
  background-repeat: no-repeat;
  background-size: 100% 45%;
  border-radius: 10px !important;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
  height: 551px;
  left: calc(50% - 350px);
  min-height: 0 !important;
  object-fit: contain;
  overflow: hidden !important;
  padding: 40px;
  position: absolute;
  right: 0;
  top: 40px;
  width: 729px;
  z-index: 50;
}

//ToolTips
.blue-icon-tooltip .tooltip-inner {
  background-color: #e7ebff;
  color: #171c8f;
  font-size: 12px;
  font-weight: 500;
}
.blue-icon-tooltip.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #e7ebff;
}

.pointer:hover {
  cursor: pointer;
}
