.custom-control-input:checked ~ .custom-control-label::before {
  color: red;
  border-color: #171c8f;
  background-color: transparent;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url(~assets/images/icons/check-icon.svg) !important;
  width: 18px;
  height: 18px;
  background-repeat: no-repeat;
  background-position: center;
  background-position-x: 4px;
  background-position-y: 5px;
}
.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::before {
  background-color: #fff;
  border: 1.2px solid #a9a9a9;
}

.custom-control-label-rounded {
  letter-spacing: 2px !important;
  font-family: 'Roboto Bold' !important;
  font-size: 12px;
  padding-top: 3px;

  &::before {
    border-radius: 50% !important;
    background-position-y: 4px !important;
  }

  &__disabled {
    pointer-events: none;
  }

  &__text-normal {
    font-size: 14px;
    font-family: 'Roboto' !important;
    letter-spacing: normal !important;
  }

  &__text-modal {
    font-family: 'Roboto Light' !important;
    letter-spacing: normal !important;
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    color: black;
    overflow-wrap: break-word;
  }
}

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: flex;
  width: 1rem;
  height: 1rem;
  content: '';
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
  color: red;
  align-items: center;
}

.text-dark {
  color: #343a40 !important;
}

.labelCheckbox {
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-size: 16px;
  margin-top: 12px;
  font-family: 'Roboto Light';
  padding-top: 2px;
  color: rgb(169, 169, 169);
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.3125rem;
  padding-left: 1.5rem;
}
