.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table,
.mat-table {
  width: 100%;
  margin-bottom: 1rem;
  color: #3e5569;
}
table {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
}
.table thead th,
.mat-table thead th.mat-header-cell {
  padding-bottom: 20px;
  border-top: 0px;
  vertical-align: bottom;
  border-bottom: 1px solid #e9ecef;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}
tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}
.table tbody td,
.mat-table tbody td.mat-cell {
  padding-top: 42px;
  font-family: 'Roboto Light';
  font-weight: 300;
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}
tbody td.elipsis,
thead th.elipsis,
tbody td.mat-cell.elipsis,
thead th.mat-header-cell.elipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

//removes lines between rows in table
.table thead th,
.mat-table thead th.mat-header-cell {
  vertical-align: bottom;
  border-bottom: 1px solid #dee2e6;
}
.table th,
.table td,
.mat-table th.mat-header-cell,
.mat-table td.mat-cell {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: none !important;
}

//dots in table
.clsGrid_dot,
.mat-table tbody td.mat-cell .clsGrid_dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  margin-bottom: -4px;
}

.clsGrid_dot.clsDot_minor {
  background-color: #ffd01b;
}
.clsGrid_dot.clsDot_info {
  background-color: #485cc7;
}
.clsGrid_dot.clsDot_major {
  background-color: #eb6907;
}
.clsGrid_dot.clsDot_medium {
  background-color: #ff9e1b;
}
.clsGrid_dot.clsDot_critical {
  background-color: #a50034;
}
.clsGrid_dot.clsDot_dark_mint {
  background-color: #48c784;
}
.clsGrid_dot.clsDot_cranberry {
  background-color: #a50034;
}
.clsGrid_dot.clsDot_brown_grey {
  background-color: #a9a9a9;
}
.clsGrid_dot.clsDot_blocker {
  background-color: #55001b;
}

.clsGrid_IconsSeparate {
  margin-right: 18px;
}

.table thead th,
.mat-table thead th.mat-header-cell {
  vertical-align: bottom;
  border-bottom: 1px solid #dee2e6;
}
@media (min-width: 1170px) {
  .table.clsFont16 tbody td,
  .mat-table.clsFont16 tbody th.mat-cell {
    font-size: 16px;
  }
}

@media (min-width: 768px) {
  .table.clsFont16 tbody td,
  .mat-table.clsFont16 tbody th.mat-cell {
    font-size: 14px;
  }
}

@media (min-width: 1170px) {
  .table tbody td,
  .mat-table tbody td.mat-cell {
    padding-top: 42px;
    font-family: 'Roboto Light';
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
}
@media (min-width: 768px) {
  .table tbody td,
  .mat-table tbody td.mat-cell {
    padding-top: 30px;
  }
}
tbody td.elipsis,
thead th.elipsis,
tbody td.mat-cell.elipsis,
thead th.mat-header-cell.elipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.table tbody td,
.mat-table tbody td.mat-cell {
  padding-top: 42px;
  font-family: 'Roboto Light';
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  border: none;
}
.no-wrap td,
.no-wrap th,
.no-wrap td.mat-cell,
.no-wrap th.mat-header-cell {
  white-space: nowrap;
}
.cell,
.v-middle td,
.v-middle th,
.vm.table td,
.vm.table th,
.waves-effect {
  vertical-align: middle;
}

.table th,
.table thead th,
.mat-table th.mat-header-cell,
.mat-table thead th.mat-header-cell {
  font-weight: 500;
}
.table thead th,
.mat-table thead th.mat-header-cell {
  padding-bottom: 20px;
  border-top: 0px;
  vertical-align: bottom;
  border-bottom: 1px solid #e9ecef;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}
.no-wrap td,
.no-wrap th,
.no-wrap td.mat-cell,
.no-wrap th.mat-header-cell {
  white-space: nowrap;
}

@media (min-width: 768px) {
  .table thead th,
  .mat-table thead th.mat-header-cell {
    font-size: 14px;
  }
}

.mat-paginator-container {
  .mat-paginator-page-size {
    display: none;
  }

  .mat-focus-indicator {
    background-color: #ffffff;
  }

  .mat-paginator-navigation-last {
    display: none;
  }

  .mat-paginator-navigation-first {
    display: none;
  }

  .mat-paginator-range-label {
    font-family: 'Roboto bold';
    font-size: 16px;
  }
}

// table {
//   table-layout: fixed !important;
// }

// th, td, thead th, tbody td, tfoot td, tfoot th {
//   width: auto !important;
// }

// tr.mat-row {
//   display: table-row;
//   vertical-align: inherit;
//   border-color: inherit;
// }
