.clsCircle {
  width: 26px;
  height: 26px;
  border-radius: 100%;
  margin-left: auto;
  cursor: pointer;
}
.clsCircle.up {
  transform: rotate(-180deg);
  background-image: url('/assets/images/icons/arrow-dropdown-blue.svg');
  background-repeat: no-repeat;
  background-position: center;
  border: solid 1px #f98389;
}
.clsCircle.down {
  transform: rotate(0deg);
  background-image: url('/assets/images/icons/arrow-dropdown.svg');
  background-repeat: no-repeat;
  background-position: center;
  border: solid 1px #cbcbcb;
}
