.clsNavbarBorder {
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 10px;
  margin-bottom: 50px;
}
.btn-link,
.btn:hover,
a {
  text-decoration: none;
}
.nav-link,
.navbar {
  padding: 0.5rem 1rem;
}
.nav-link {
  display: block;
  cursor: pointer;
}
.nav-link.nav-link-tab {
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2px;
  color: black;
  margin-left: 21px;
  text-transform: uppercase;
}
.nav-link.nav-link-tab:hover,
.nav-link.nav-link-tab.active {
  font-family: 'Roboto Bold';
  font-weight: normal;
  color: black;
}
.nav-link.nav-link-tab.active:after {
  content: '';
  display: block;
  width: 65%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: -18px;
  border-bottom: 2px solid #171c8f;
}
